import React from 'react';
import { withTranslation } from 'react-i18next';
import './Dashboard.scss';
import { AuthContext, AuthSession } from '../../contexts/AuthContext';
import Card from '../../components/Card/Card';
import PartnerBloc from '../../components/PartnerBloc/PartnerBloc';
import { loader } from '../../components/Loader/LoaderManager';
import Title from '../../components/Title/Title';
import { Button, ButtonType, toast, Icon, IconType } from '@om/design-system';
import ActionCard from '../../components/ActionCard/ActionCard';
import { getEshop, getTicketing } from '../../utils';
import { Membership_Type } from '../../models/Membership';

class Dashboard extends React.Component<any> {
  state = {
    alerts: []
  }

  constructor(props: any) {
    super(props);
    this.state = {
      alerts: []
    };
  } 
  
  isUnderEighteen(dateOfBirth: Date) {
    if (!dateOfBirth) return false;

    var ageDifMs = new Date().getTime() - dateOfBirth.getTime();
    var ageDate = new Date(ageDifMs);
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (age > 17) {
      return true
    } else {
      return false
    }
  }

  getMembershipCard() {
    return new Promise((resolve, reject) => {
      const { token, user } = this.context as AuthSession;

      if (!user?.membership?.id) {
        return;
      }

      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'x-token': token! }
      };
      fetch(`/api/wallet`, options)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch((err: any) => reject(err));
    });
  }

  clickWallet = () => {
    loader.show();

    const { user } = this.context as AuthSession;
    const { t } = this.props;

    if (!user?.membership?.id) {
      loader.destroy();
      toast.show({ content: t('membership.wallet_error'), isError: true });
      return;
    }

    this.getMembershipCard()
      .then((json: any) => {
        const url = json.url;
        if (!url) {
          loader.destroy();
          toast.show({ content: t('membership.wallet_error'), isError: true });
          return;
        }

        // window open does not work on safari
        var a = document.createElement('a');
        a.target = "_blank";
        a.href = url;
        a.click();
        loader.destroy();
      })
      .catch((err: any) => {
        loader.destroy();
        toast.show({ content: t('membership.wallet_error'), isError: true });
      })
      ;
  }

  redirectTickets = () => {
    const redirect = "/fr/account/tickets";
    return `${getTicketing()}${redirect}`;
  }

  render() {
    const { t } = this.props;
    const { user } = this.context as any;

    if (!user) { return <></> }

    return <>
      <div className='dashboard-header'>
        <div className='name-and-member desktop'>
          <Title>
            <div className='primary'>{user.firstName}</div>
            <div>{user.lastName}</div>
          </Title>
        </div>

        <div className='member-time'>
          <div className='first-letter-uppercase'>{t('dashboard.omMember', { date: user.createdAt.toLocaleDateString("fr") })}</div>
        </div>
      </div>

      <div className='dashboard'>
        {/* Member Card */}
        <div className='card item'>
          <Card membership={user.membership} />
          <div className='cta'>
            {user.membership ? <>
              <div className='subheader desktop'>{t('membership.qr_info')}</div>
              <Button type={ButtonType.Primary} onClick={this.clickWallet}><Icon type={IconType.Wallet} fill='white' /> {t('ticket.add_wallet')}</Button>
              <Button type={ButtonType.Secondary} href='/account/member'>{t('membership.button_advantages')}</Button>
            </> : <>
              <Button type={ButtonType.Secondary} href='https://membres.om.fr'>{t('membership.button')}</Button>
            </>}
          </div>
        </div>

        {/* Shortcuts */}
        <div className='bloc'>
          <div className="grid-two-columns">
            {/* Shop */}
            <ActionCard goTo={getEshop()} ctaName={t('dashboard.shop_cta')} title={t('dashboard.shop_title')} icon="🛒">
              {user.membership && user.membership.type !== Membership_Type.membership_virage &&
                <div className='desktop'>{t('dashboard.shop_member_info')}</div>
              }
            </ActionCard>

            {/* Resale */}
            {user.membership && user.membership.type !== Membership_Type.membership_virage ? <>
              <ActionCard goTo={this.redirectTickets} ctaName={t('membership.resale_cta')} title={t('membership.resale_title')} icon="🎟️">
                <div className='desktop'>{t('membership.resale_subtitle')}</div>
              </ActionCard>
            </> : <>
              <div className='desktop'></div>
            </>}

            {/* OM Stadium Tour */}
            <ActionCard goTo={"https://www.orangevelodrome.com/om-tour"} ctaName={t('dashboard.om_tour_cta')} title={t('dashboard.om_tour_title')} icon="🏆">
              {user.membership && user.membership.type !== Membership_Type.membership_virage &&
                <div className='desktop'>{t('dashboard.om_tour_description')}</div>
              }
            </ActionCard>

            {/* Match alerts */}
            {/*
            <ActionCard goTo={"/alerts"} ctaName={t('dashboard.match_alert')} title={t('dashboard.match_alert_cta')} icon="🔔" isNewTab={false} >
            </ActionCard>
            */}
          </div>
        </div>

        {/* Partners */}
        <div className='bloc bloc-partners'>
          <div className='title-header'>{t('dashboard.for_you')}</div>
          <div className='partners'>
            <PartnerBloc
              condition={this.isUnderEighteen(user.birthdate)}
              logo_url={'https://www.om.fr/sites/default/files/2024-03/logo-psel-1l-2024.png'}
              link={"https://www.fdj.fr/inscription/parions-sport-enligne/"}
              alt="Parions Sport"
            >
              {t('dashboard.partner.parionssport')}
            </PartnerBloc>
            <PartnerBloc
              condition={this.isUnderEighteen(user.birthdate) && user.membership !== null}
              logo_url={'https://www.om.fr/sites/default/files/2024-03/logo-psel-1l-2024.png'}
              link={"https://www.fdj.fr/inscription/parions-sport-enligne/cac/?xtor=AL-1014-%5BPSEL_PARTENARIAT_OM_ACQ_WEB_GENERIC_INSCRIPTION___%5D-%5BOM%5D&campaign=pbb10&dclid=CIn2yevh54UDFZ9IHQkdH90HEQ"}
              alt="Parions Sport"
              isPBB={true}
            >
              {t('dashboard.partner.parionssport_pbb')}
            </PartnerBloc>
            <PartnerBloc
              condition={user.membership !== null}
              logo_url={'https://www.laprovence.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo-lp.45279693.png&w=1080&q=75'}
              link={"https://abonnement.laprovence.com/abonnement/offre-numerique-mensuelle-abonnes-om?at_medium=display&at_campaign=partenariatOM&at_channel=siteOM&at_format=banniere"}
              alt="La Provence"
              isPBB={true}
            >
              {t('dashboard.partner.laprovence')}
            </PartnerBloc>
            <PartnerBloc
              condition={user.membership !== null}
              logo_url={'https://www.om.fr/sites/default/files/2024-04/CEBE-Logo.png'}
              link={"https://www.cebe.com/fr/?utm_source=sitecebe&utm_medium=promopbb&utm_campaign=encartom"}
              alt="Cébé"
              isPBB={true}
            >
              {t('dashboard.partner.cebe')}
            </PartnerBloc>
          </div>
        </div>

        {/* Match alerts */}
        {/* <div className='bloc'>
          <div className='title-header'>{t('dashboard.match_alert')}</div>
          <div className='match-alerts'>
            <MatchList alerts={alerts} isSmall={true} />
          </div>
        </div> */}
      </div>
    </>;
  }
}

Dashboard.contextType = AuthContext;
export default withTranslation()(Dashboard);
