import Address from "./Address";
import Consent, { ConsentName } from "./Consent";
import Membership from './Membership';
import Ticket from "./Ticket";

export enum Gender {
    male = "male",
    female = "female",
    other = "other"
}

export default class User {
    id: string;
    createdAt: Date;
    email: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    consents: Consent[];
    ticketingID: string;
    birthdate?: Date;
    phoneNumber: string;
    emailVerified: boolean;
    address: Address;
    membership?: Membership;
    deleteAccountDate?: Date;
    isAdmin: boolean;
    isPartner: boolean;
    isLite: boolean;
    tickets?: Ticket[];
    alerts?: string[];

    public constructor(id: string, createdAt: Date, email: string, firstName: string, lastName: string, gender: Gender, consents: Consent[], ticketingID: string, phoneNumber: string, address: Address, emailVerified: boolean, membership: any, isAdmin: boolean, isPartner:boolean, isLite: boolean, birthdate?: Date, deleteAccountDate?: Date, alerts?: string[]) {
        this.id = id;
        this.createdAt = createdAt;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.gender = gender;
        this.consents = consents;
        this.ticketingID = ticketingID;
        this.birthdate = birthdate;
        this.deleteAccountDate = deleteAccountDate;
        this.phoneNumber = phoneNumber;
        this.emailVerified = emailVerified;
        this.address = address;
        this.membership = membership;
        this.isAdmin = isAdmin
        this.isPartner = isPartner
        this.isLite = isLite
        this.alerts = alerts
    }

    /**
     * Map a R5 server object into a User object
     * @param r5 
     * @returns 
     */
    static mapFromReachfive(r5: any): User {                
        // map consents first
        let consents: Consent[] = [];
        if (!!r5.consents) {
            consents = Object.keys(r5.consents).map((name: string) => Consent.mapFromReachfive(name, r5.consents[name]));
        }
        // map addresses then
        const address = r5.addresses?.filter((a: any) => a.default === true)[0];

        // Membership
        const memberProgram = Membership.getActiveMembership(r5.customFields?.populations);
        const membership = memberProgram ? new Membership(r5.customFields?.dt_customer_id, memberProgram) : null;

        return new User(
            r5.id,
            new Date(r5.createdAt),
            r5.email,
            r5.givenName,
            r5.familyName,
            r5.gender as Gender,
            consents,
            r5.customFields?.dt_customer_id,
            r5.phoneNumber,
            Address.mapFromReachfive(address),
            r5.emailVerified,
            membership,
            r5.customFields?.is_admin,
            r5.customFields?.is_partner,
            r5.liteOnly,
            !!r5.birthdate ? new Date(r5.birthdate) : undefined,
            !!r5.customFields?.delete_account_date ? new Date(r5.customFields.delete_account_date) : undefined,
            r5.customFields?.ticketing_alerts,
        );
    }

    /**
     * Workaround for bypassing R5 limitations about consent "disabled" field
     * Indeed this func provides an easy to access consents by key
     * Whereas it could be preferred to loop over all consents
     * 
     * Convert list Consent[] to dict { ConsentName: Consent }
     * decrease drastically n-complexity
     * @returns { ConsentName: Consent }
     */
    getConsentsAsDict(): { ConsentName: Consent } {
        const dict: any = {};
        // init consent with an empty dict
        Object.keys(ConsentName).forEach((cn: any) => {
            const name = ConsentName[cn as keyof typeof ConsentName];
            dict[name] = new Consent(name);
        });
        // now fill with values
        this.consents.forEach((c: Consent) => {
            if (Object.values(ConsentName).includes(c.name)) {
                dict[c.name] = c;
            }
        });
        return dict;
    }

    /**
     * User data to update 
     */
    set(user: User) {
        if (!!user.id) this.id = user.id;
        if (!!user.email) this.email = user.email;
        if (!!user.firstName) this.firstName = user.firstName;
        if (!!user.lastName) this.lastName = user.lastName;
        if (!!user.gender) this.gender = user.gender;
        if (!!user.consents) this.setConsents(user.consents);
        if (!!user.ticketingID) this.ticketingID = user.ticketingID;
        if (!!user.birthdate) this.birthdate = user.birthdate;
        if (!!user.phoneNumber) this.phoneNumber = user.phoneNumber;
        if (!!user.emailVerified) this.emailVerified = user.emailVerified;
        if (!!user.address) this.address = user.address;
    }

    setConsents(data: any) {
        var consents: Consent[] = [];
        Object.keys(ConsentName).forEach((cn: any) => {
            consents.push(new Consent(ConsentName[cn as keyof typeof ConsentName],data[cn], data["date"]));
        });
        this.consents = consents;
    }

    hasMissingFields() {
        if (!this.birthdate) { return true }
        if (!this.gender) { return true }
        if (!this.firstName) { return true }
        if (!this.lastName) { return true }
        return false;
    }
}