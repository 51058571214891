import React from 'react';
import { withTranslation } from 'react-i18next';
import Checkbox from '../../components/Checkbox';
import { Button, ButtonType, TextField, toast } from '@om/design-system';
import { AuthContext } from '../../contexts/AuthContext';
import { ConsentName } from '../../models/Consent';
import withRouter from '../../withRouter';

const ID_FORM = "prefs-form";

class PreferenceForm extends React.Component<any> {

    state = {
        errorMsg: null
    }

    submit = (event: any) => {
        event.preventDefault();
        var form = document.getElementById(ID_FORM) as HTMLFormElement;
        var formData = new FormData(form);
        const { t, user: props_user } = this.props;
        const { user: context_user } = this.context as any;
        const user = props_user || context_user;
        const date = new Date();
        const email = formData.get("email");
        const { id } = this.props.params;

        // if not connected and email empty
        if((!user || !user.email) && !email) {
            toast.show({ content: t('form.error'), isError: true});
            return;
        }

        const data = {
            id: user?.id || id,
            email: email || user.email,
            date: date.toISOString(),
            news: formData.get("news") === "on",
            ticketing: formData.get("ticketing") === "on",
            eshop: formData.get("eshop") === "on",
            partners: formData.get("partners") === "on",
        };
        user?.set({ consents: data });

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        fetch('/api/preferences', options)
            .then(data => {
                toast.show({ content: t('form.success'), isError: false });
            }).catch((error: any) => {
                console.log(error);
                toast.show({ content: t('form.error'), isError: true});
            });
        ;
    }

    optout = (event: any) => {
        var form = document.getElementById(ID_FORM) as HTMLFormElement;
        var formData = new FormData(form);
        const current = !!formData.get("optout");
        const optout = !current;

        (document.getElementById('optout') as any).checked = optout;
        (document.getElementById('news') as any).checked = !optout;
        (document.getElementById('ticketing') as any).checked = !optout;
        (document.getElementById('eshop') as any).checked = !optout;
        (document.getElementById('partners') as any).checked = !optout;
    }

    optin = () => {
        (document.getElementById('optout') as any).checked = false;
    }

    render() {
        const { t, user: props_user } = this.props;
        const { user: context_user } = this.context as any;
        const user = props_user || context_user;

        const consents = user?.getConsentsAsDict() ?? [];

        const news = ConsentName.news in consents ? consents[ConsentName.news]?.granted : true;
        const ticketing =  ConsentName.ticketing in consents ? consents[ConsentName.ticketing]?.granted : true;
        const eshop =  ConsentName.eshop in consents ? consents[ConsentName.eshop]?.granted : true;
        const partners =  ConsentName.partners in consents ? consents[ConsentName.partners]?.granted : true;        

        const optout = !news && !ticketing && !eshop && !partners;
        
        return <div className="left-content-size">
            <form id={ID_FORM} onSubmit={this.submit} className='form preference-form'>
                {/* Add email in form if needed */}
                {(!user || !user.email) && 
                    <TextField name="email" type="email" placeholder={t('profile.email')} required />
                }

                <span className="row">
                    <div className='col-8'>
                        <div className='category variant left'>{t("prefs.news.title")}</div>
                        <label className='variant'>{t("prefs.news.description")}</label>
                    </div>
                    <div className="col transform02"><Checkbox id="news" defaultChecked={news} onChange={this.optin} /></div>
                    <div className="w-100"></div>
                    <div className='col-8'>
                        <div className='category variant left'>{t("prefs.ticketing.title")}</div>
                        <label className='variant'>{t("prefs.ticketing.description")}</label>
                    </div>
                    <div className="col transform02"><Checkbox id="ticketing" defaultChecked={ticketing} onChange={this.optin} /></div>
                    <div className="w-100"></div>

                    <div className='col-8'>
                        <div className='category variant left'>{t("prefs.eshop.title")}</div>
                        <label className='variant'>{t("prefs.eshop.description")}</label>
                    </div>
                    <div className="col transform02"><Checkbox id="eshop" defaultChecked={eshop} onChange={this.optin} /></div>
                    <div className="w-100"></div>

                    <div className='col-8'>
                        <div className='category variant left'>{t("prefs.partner.title")}</div>
                        <label className='variant'>{t("prefs.partner.description")}</label>
                    </div>
                    <div className="col transform02"><Checkbox id="partners" defaultChecked={partners} onChange={this.optin} /></div>
                </span>
                <div className="w-100"></div>
                <div className="fade show alert alert-secondary pointer" role="alert" onClick={this.optout}>
                    <div className="row">
                        <div className="col transform02"><Checkbox id="optout" defaultChecked={optout} /></div>
                        <label className='col-10 variant pointer'>{t("prefs.optout.description")}</label>
                    </div>
                </div>
                <Button type={ButtonType.Submit}>{t('save')}</Button>
            </form>
        </div>
    }
}

PreferenceForm.contextType = AuthContext;
export default withTranslation()(withRouter(PreferenceForm));