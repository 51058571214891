import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './Member.scss';
import logo from '../../assets/img/LOGO_PBB_FONCE.png';
import { AuthContext } from '../../contexts/AuthContext';
import QRCodeStyling from "qr-code-styling";
import Card from '../../components/Card/Card';
import { getEshop, getOMFR, getTicketing } from '../../utils';
import { Button, ButtonType, Icon, IconType, toast } from '@om/design-system';
import { Membership_Type } from '../../models/Membership';
import { loader } from '../../components/Loader/LoaderManager';

const Member = () => {
  const { t } = useTranslation();
  const {  token, user } = useContext(AuthContext);
  const ref = useRef(null as any);

  const qrCode = new QRCodeStyling({
    width: 250,
    height: 250,
    type: 'svg',
    image: user && user.membership && user.membership.type !== Membership_Type.om_legendes ? logo : '',
    dotsOptions: {
      color: "#092C4D",
      type: "rounded",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 3,
      imageSize: 0.4,
    },
    qrOptions: {
      mode: 'Numeric',
      typeNumber: 0
    },
    cornersSquareOptions: {
      type: 'extra-rounded'
    },
    cornersDotOptions: {
      type: 'dot'
    },
  });

  const getMembershipCard = () => {
    return new Promise((resolve, reject) => {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'x-token': token! }
      };
      
      fetch(`/api/wallet`, options)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch((err: any) => reject(err));
    });
  }

  const clickWallet = () => {
    loader.show();

    if (!user?.membership?.id) {
      loader.destroy();
      toast.show({ content: t('membership.wallet_error'), isError: true });
      return;
    }

    getMembershipCard()
      .then((json: any) => {
        const url = json.url;
        if (!url) {
          loader.destroy();
          toast.show({ content: t('membership.wallet_error'), isError: true });
          return;
        }

        // window open does not work on safari
        var a = document.createElement('a');
        a.target = "_blank";
        a.href = url;
        a.click();
        loader.destroy();
      })
      .catch((err: any) => {
        loader.destroy();
        toast.show({ content: t('membership.wallet_error'), isError: true });
        return;
      })
      ;
  }

  useEffect(() => {
    if (!!user?.ticketingID) {
      qrCode.append(ref.current);
      qrCode.update({ data: `${user?.ticketingID}` });

      console.log(document.getElementById("qr-container"))
      const svg = document.getElementById("qr-container")?.querySelector("svg");            
      svg?.setAttribute("viewBox", "0 0 250 250");
      svg?.setAttribute("preserveAspectRatio", "xMidYMid meet");
      if (!!svg) {
        svg.style.width = "100%";
      }
    }
  }, []);

  const displayContent = (e: any) => {
    e.stopPropagation()
    e.target.classList.toggle('display')
  }

  if (!user || !user.membership) { return <>
    {/* Member Card */}
    <div className='membership-subscribe'>
      <div className='card bloc'>
        <Card membership={null} />
				<Button type={ButtonType.Link} isTargetblank={true} href='https://membres.om.fr'>{t('membership.button')}</Button>
      </div>
    </div>
  </> }

  return <>
    <div className='membership'>
      <div className='title-header'>
        <div className='logo'><img src={user?.membership?.type === Membership_Type.om_legendes ? "https://www.om.fr/sites/default/files/inline-images/OM_Legendes_fondclair.png" : logo} alt="Logo OM Légendes" /></div>
        <h1>{user?.membership?.type === Membership_Type.om_legendes ? "Membre OM Légendes" : "Membre Peuple Bleu&Blanc"}</h1>
      </div>

      <div className='membership-content'>
        {/* QR Code */}
        <div className='card accordion card-column' onClick={(e) => displayContent(e)}>
          <div className='header mobile'>Mon QR Code</div>
          <div className='content'>
            <div className='member-account-info'>
              <div className='subheader'>{t('membership.qr_info')}</div>
              <div className='qrcode'>
                <div className='frame'><div id="qr-container" ref={ref} /></div>
              </div>
              <Button type={ButtonType.Primary} onClick={clickWallet}><Icon type={IconType.Wallet} fill='white' /> {t('ticket.add_wallet')}</Button>
            </div>
          </div>
        </div>

        <div className='multi-advantages'>
          {/* Advantages List */}
          <div className='content advantages'>
            {/* Ticket */}
            {user.membership && user.membership.type !== Membership_Type.membership_virage &&<div className='card'>
              <div className='title'>{t('membership.ticket')}</div>
              <div className='first-letter-uppercase subheader'>{t('membership.ticket_info')}</div>
              <Button type={ButtonType.Secondary} href={getTicketing()} isTargetblank={true} className='secondary'>{t('membership.ticket_cta')}</Button>
            </div>}

            {/* Shop */}
            <div className='card'>
              <div className='title'>{t('membership.shop')}</div>
              <div className='first-letter-uppercase subheader'>{t('membership.shop_info')}</div>
              <Button type={ButtonType.Secondary} href={getEshop()} isTargetblank={true} className='secondary'>{t('membership.shop_cta')}</Button>
            </div>

            {/* Experience */}
            <div className='card'>
              <div className='title'>{t('membership.experience')}</div>
              <div className='first-letter-uppercase subheader'>{t('membership.experience_info')}</div>
              <Button type={ButtonType.Secondary} href='https://www.orangevelodrome.com/om-tour' isTargetblank={true} className='secondary'>{t('membership.experience_cta')}</Button>
            </div>

            {/* Content */}
            <div className='card'>
              <div className='title'>{t('membership.content')}</div>
              <div className='first-letter-uppercase subheader'>{t('membership.content_info')}</div>
              <Button type={ButtonType.Secondary} href={getOMFR() + "/videos"} isTargetblank={true} className='secondary'>{t('membership.content_cta')}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default Member;
