import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Signup from './screens/Signup';

import { useEffect, useState } from "react";
import i18n from "./i18n";

import Logout from "./screens/Logout";
import { auth, getHome, getLogin } from "./utils";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import Addresses from './screens/Addresses/Addresses';
import ForgotPassword from './screens/Password/ForgotPassword';
import Optout from './screens/Optout';
import Password from './screens/Password/Password';
import CommunicationPreferences from './screens/Preferences/UserPreferences';
import Connect from './screens/Connect/Connect';
import Redirect from './screens/Redirect';
import ResetEmail from './screens/ResetEmail';
import ResetPassword from './screens/ResetPassword';
import Security from './screens/Security/Security';
import VerifyEmail from './screens/VerifyEmail/VerifyEmail';
import PreferenceCenter from './screens/Preferences/PreferenceCenter';
import Login from './screens/Login/Login';
import { AuthContext, AuthSession } from "./contexts/AuthContext";
import RequiredProfile from './screens/Profile/RequiredProfile';
import { AuthRoute } from './components/AuthRoute';
import Loading from './components/Loading/Loading';
import Member from './screens/Member/Member';
import Dashboard from './screens/Dashboard/Dashboard';
import Business from './screens/Business/Business';
import NoMatch from './screens/NoMatch/NoMatch';
import Scan from './screens/Business/Scan';
import Ticket from './screens/Ticketing/Ticket';
import AdminUser from './screens/Admin/AdminUser';
import GeneralForm from './screens/Profile/GeneralForm';

import { createClient } from '@reachfive/identity-core';
import Alert from './screens/Alert/Alert';
import AlertsList from './screens/AlertsList/AlertsList';
import SecurityForm from './screens/Security/DeleteAccount/DeleteAccount';
import withRouter from './withRouter';
import SubmitValidation from './screens/Alert/SubmitValidation';

if (/preprod/.test(process.env.REACT_APP_SSO_URL!)) {
  document.title = `[TEST] OM Connect`;
}

window.r5 = createClient({
  domain: process.env.REACT_APP_SSO_DOMAIN!,
  clientId: process.env.REACT_APP_R5_CLIENT_ID!,
  language: i18n.language, // de / en / es / fr / hu / it / nl / ru / sk / pt
});

const App = (props: any) => {
  const [session, setSession] = useState(new AuthSession({}));
  const { search } = useLocation();

  useEffect(() => {
    window.r5
      .getSessionInfo()
      .then((session: any) => {
        const isAuth = session.isAuthenticated;
        // auth user to R5 and API
        auth()
          .then((result: any) => {
            setSession({ logged: isAuth, user: result.user, token: result.token });
          })
          .catch((err: any) => {
            console.error(err);
            setSession({ logged: isAuth });
          })
          ;
      })
      .catch((err: any) => {
        setSession({ logged: false });
      })
  }, []);

  if (session.logged === undefined) {
    return <Loading />;
  }

  const AlertNodeComponent = (props: any) => {
    const id = props.params.id;
    return session.logged ? <Navigate to={`/account/alert/${id}${search}`} /> : <Alert />;
  }
  const AlertNode = withRouter(AlertNodeComponent);

  session.setSession = setSession;

  return <>
    <AuthContext.Provider value={session}>
      {/* <Accessibility /> */}
      <Routes>
        <Route path='*' element={session.logged ? <Navigate to={getHome() + search} /> : <Navigate to={getLogin() + search} />} />
        <Route path='/' element={<AuthRoute><Login /></AuthRoute>} />
        <Route path='/login' element={session.logged ? <Navigate to={getHome() + search} /> : <Login />} />
        <Route path='/not-found' element={<NoMatch />} />

        <Route path='/logout' element={<AuthRoute><Logout /></AuthRoute>} />
        <Route path='/user/logout' element={<AuthRoute><Logout /></AuthRoute>} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/verify-email' element={<VerifyEmail />} />
        <Route path='/reset-email' element={<AuthRoute><ResetEmail /></AuthRoute>} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/optout' element={<Optout />} />
        <Route path='/redirect' element={<Redirect />} />
        <Route path='/preferences/:id' element={<PreferenceCenter />} />
        <Route path='/business/:id' element={<Business />} />
        <Route path='/alerts' element={session.logged ? <Navigate to={`/account/alerts${search}`} /> : <AlertsList />} />
        <Route path='/alert/:id' element={<AlertNode />}/>
        <Route path='/alert/validation' element={<SubmitValidation />} />
        <Route path='/account' element={<AuthRoute><Connect /></AuthRoute>}>
          <Route path='/account/' element={<AuthRoute><Dashboard /></AuthRoute>} />
          <Route path='/account/etickets' element={<AuthRoute><Ticket /></AuthRoute>} />
          <Route path='/account/profile' element={<AuthRoute><GeneralForm /></AuthRoute>} />
          <Route path='/account/addresses' element={<AuthRoute><Addresses /></AuthRoute>} />
          <Route path='/account/member' element={<AuthRoute><Member /></AuthRoute>} />
          <Route path='/account/preferences' element={<AuthRoute><CommunicationPreferences /></AuthRoute>} />
          <Route path='/account/security' element={<AuthRoute><Security /></AuthRoute>} />
          <Route path='/account/security/password' element={<AuthRoute><Password /></AuthRoute>} />
          <Route path='/account/security/delete-account' element={<AuthRoute><SecurityForm /></AuthRoute>} />
          <Route path='/account/scan' element={<AuthRoute><Scan /></AuthRoute>} />
          <Route path='/account/admin/user' element={<AuthRoute><AdminUser /></AuthRoute>} />
          <Route path='/account/alerts' element={<AlertsList />} />
          <Route path='/account/alert/:id' element={<Alert />} />
        </Route>
        <Route path='/profile' element={<AuthRoute>{session.user?.isLite === true ? <Signup /> :  <RequiredProfile />}</AuthRoute>} />
      </Routes>
      <footer>
      </footer>
    </AuthContext.Provider>
  </>;
}

export default App;