import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './VerticalToolbar.scss';
import { AuthContext } from '../../contexts/AuthContext';

import { Icon, IconType } from '@om/design-system';
import { Membership_Type } from '../../models/Membership';

class VerticalToolbar extends React.Component<any> {

  showMenu = () => {
    const menu = document.getElementById('menu') as any;
    menu.classList.toggle('visible');
  }

  closeMenu = () => {
    const menu = document.getElementById('menu') as any;
    menu.classList.remove('visible');
  }

  render() {
    const { t } = this.props;
    const { user } = this.context as any;

    return <div className={'menu'}>
      {/* <div className={'desktop user-profile'}>{user.givenName} {user.familyName}</div> */}
      <ul id='menu'>
        <div className='mobile-padding'>
          <div>
            <NavLink to='/account/' className={({ isActive }) => (isActive ? 'active' : '')} onClick={this.closeMenu}>
              <li className='menu-item'>
                <Icon type={IconType.Dashboard} />
                <span className='name'>{t('navigation.dashboard')}</span>
              </li>
            </NavLink>
            {(user.isAdmin || user.isPartner) &&
              <NavLink to='/account/scan' className={({ isActive }) => (isActive ? 'active' : '')} onClick={this.closeMenu}>
                <li className='menu-item'>
                  <Icon type={IconType.Camera} />
                  <span className='name'>{t('navigation.scan')}</span>
                </li>
              </NavLink>
            }
          </div>
          <div>
            <div className='label'>{t('navigation.identity')}</div>
            {!user.isPartner && <NavLink to='/account/etickets' className={({ isActive }) => (isActive ? 'active' : '')} onClick={this.closeMenu}>
              <li className='menu-item'>
                <Icon type={IconType.Tickets} />
                <span className='name'>{t('navigation.tickets')}</span>
              </li>
            </NavLink>}
            <NavLink to='/account/profile' className={({ isActive }) => (isActive ? 'active' : '')} onClick={this.closeMenu}>
              <li className='menu-item'>
                <Icon type={!!user.isAdmin ? IconType.Admin : IconType.Profile} />
                <span className='name'>{t('navigation.profile')}</span>
              </li>
            </NavLink>
          </div>
          {user.membership && <div>
            {user.membership.type === Membership_Type.om_legendes ? <>
              <div className='label'>{t('navigation.omLegendes')}</div>
            </> : <>
              <div className='label'>{t('navigation.clubBleuEtBlanc')}</div>
            </>}
            <NavLink to='/account/member' className={({ isActive }) => (isActive ? 'active' : '')} onClick={this.closeMenu}>
              <li className='menu-item'>
                <Icon type={IconType.Member} />
                <span className='name'>{t('navigation.advantages')}</span>
              </li>
            </NavLink>
          </div>}
          <div>
            <div className='label'>{t('navigation.security')}</div>
            <NavLink to='/account/preferences' className={({ isActive }) => (isActive ? 'active' : '')} onClick={this.closeMenu}>
              <li className='menu-item'>
                <Icon type={IconType.CommPreferences} />
                <span className='name'>{t('navigation.communication_preferences')}</span>
              </li>
            </NavLink>
            {!user.isPartner && <NavLink to='/account/security' className={({ isActive }) => (isActive ? 'active' : '')} onClick={this.closeMenu}>
              <li className='menu-item'>
                <Icon type={IconType.Security} />
                <span className='name'>{t('navigation.security')}</span>
              </li>
            </NavLink>}
            <NavLink to='/logout' className={({ isActive }) => (isActive ? 'active red' : 'red')} onClick={this.closeMenu}>
              <li className='menu-item'>
                <Icon type={IconType.Logout} />
                <span className='name'>{t('navigation.signout')}</span>
              </li>
            </NavLink>
          </div>
          {user.isAdmin && <div>
            <div className='label'>{t('navigation.admin')}</div>
            <NavLink to='/account/admin/user' className={({ isActive }) => (isActive ? 'active' : '')} onClick={this.closeMenu}>
              <div className='menu-item'>
                <Icon type={IconType.Admin} />
                <span className='name'>{t('navigation.user')}</span>
              </div>
            </NavLink>
          </div>}
        </div>
      </ul>

      {/* Mobile */}
      <div className='mobile top-bar'>
        <div className={'user-profile'}>{user.firstName} {user.lastName}</div>
        <svg role="img" aria-label={t('navigation.show')} className={'mobile'} onClick={this.showMenu} xmlns='http://www.w3.org/2000/svg' height='32' viewBox='0 96 960 960' width='32' preserveAspectRatio='xMidYMid meet'>
          <path d='M150 816q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150 756h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810 816H150Zm0-210q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150 546h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810 606H150Zm0-210q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150 336h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810 396H150Z' />
        </svg>
      </div>
    </div>
      ;
  }
}

VerticalToolbar.contextType = AuthContext;
export default withTranslation()(VerticalToolbar);